.review_btn {
    border-radius: 2rem;
    background-color: #af8965;
    border: none;
    color:#e3e3e3;
    padding: 0.5rem 2rem;
  }
  
  .rating {
    margin-top: 10rem;
  }
  
  /* Ratings */
  
  .rating-outer {
    display: inline-block;
    position: relative;
    font-family: FontAwesome;
    color: #af8965;
  }
  
  .rating-outer::before {
    content: "\f006 \f006 \f006 \f006 \f006";
  }
  
  .rating-inner {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
    width: 0;
  }
  
  .rating-inner::before {
    content: "\f005 \f005 \f005 \f005 \f005";
    color: #af8965;
  }
  
  .stockCounter input {
    border: none;
    width: 3rem;
    text-align: center;
  }
  
  .plus,
  .minus {
    padding: 0.1rem 0.5rem;
  }
  
  .stockCounter input::-webkit-outer-spin-button,
  .stockCounter input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .form-control:disabled,
  .form-control[readonly] {
    background-color: white;
  }
  
  .orangeColor {
    color: rgba(174, 0, 255, 0.692);
  }
  
  .greenColor {
    color: green;
  }
  
  #stock_status {
    font-size: 1.1rem;
    font-weight: bold;
    margin-left: 0.3rem;
  }
  
  .rating {
    margin-top: 10rem;
  }
  
  .stars {
    height: 100px;
    display: flex;
    align-items: center;
    padding-left: 0;
  }
  
  .star {
    display: inline;
    list-style: none;
    font-size: 3rem;
    padding-left: 0.9rem;
    color: #e3e3e3;
  }
  
  .star:first-child {
    padding-left: 0;
  }
  
  .orange {
    color: #af8965;;
  }
  
  .yellow {
    color: #fdcc0d;
  }
  
  .review_user {
    font-size: 0.8rem;
    color: grey;
  }
  